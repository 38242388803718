@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.footer
	&__top
		border-top: 1px solid $dark-grey3
		padding: rems(30) rems(30) rems(40)

		@include media-breakpoint-up(lg)
			display: flex
			justify-content: space-between
			padding: rems(24) rems(20) rems(24) rems(34)

	&__logo
		display: block
		margin-bottom: rems(20)

		@include media-breakpoint-up(lg)
			margin-right: rems(16)
			margin-bottom: 0

	&__text-wrap

	&__text
		font-family: $font-additional
		font-weight: 400
		@include fontSize(12,22)
		color: $grey

		a
			color: $grey
		
		& + &
			margin-top: rems(2)

	&__bottom
		border-top: 1px solid $dark-grey3
		padding: rems(40) rems(30) rems(35)

		@include media-breakpoint-up(lg)
			padding: rems(24) rems(42)

	&__nav

	&__list
		display: flex
		align-items: center
		flex-wrap: wrap
		margin: 0 rems(-16)
		justify-content: space-between

		@include media-breakpoint-up(lg)
			justify-content: flex-start

	&__item
		padding: rems(7) rems(16)

		@include media-breakpoint-up(lg)
			padding: 0 rems(16)

		&:last-child
			margin-right: 0

	&__link
		font-weight: 500
		@include fontSize(12,22)
		color: $white

		@include media-breakpoint-up(lg)
			@include fontSize(14,25)
